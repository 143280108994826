// @import url('https://fonts.googleapis.com/css?family=Proza+Libre');
@import url('https://fonts.googleapis.com/css?family=Josefin+Slab');


$primary: #7fb8db; /* MAIN COLOR */
$secondary: #ebb2d1; /* SECONDARY COLOR */
$gray: #666;
$blk: #222;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Josefin Slab', serif;
  color: $gray;
}
.navbar {
  margin-bottom: 0;
}
.top-pad {
  font-size: 1.3em;
  color: darken($blk, 20%);
}
.navbar-nav {
    margin: 0 -15px;
  }
  .nav .nav-divider {
    margin: 0;
  }
.navbar, .navbar-header{
  height: 100px;
  position: relative;

  .navbar-nav>li>a {
    line-height:68px;
    font-size: 1.3em;
    font-weight: bold;
    background: $wht;
    &:focus {
      background: $wht;
    }
    @media (max-width:767px){
      line-height: 20px;
    }
  }
  @media (max-width: 767px){
    height: 50px;
    .logo {
      max-height: 50px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.cta-box{
  margin-top: 30px;
  a {
    font-size: 1.5em;
    color: darken($primary, 50%);
    background: $secondary;
    text-decoration: none;
    padding: 25px 35px;
    margin: 10px;
    @media(max-width:460px){
      padding: 15px 20px;
      font-size: 1em;
      margin: 0px;
    }
    &:hover{
      background: darken($primary,20%);
      box-shadow: 0px 0px 10px $wht;
      color: $wht;
    }
  }
}
.hero-slider{
  // margin-top: 100px;
}
.hero-info{
  padding: 50px 0px;
  p {
    font-size: 1.3em;
    padding: 0 20px;
  }
}
.hero-quote {
    padding: 100px 0;
    @media(max-width: 767px){
      padding:40px 0;
    }
    background: $primary;
  h3 {
    font-size: 2em;
    line-height:1.6em;
    color: $wht;
    @media(max-width:767px){
      font-size: 1.2em;
    }
  }
}
.hero-process {
  padding: 60px 0;
  background-color: #e2e2e2;
  .col-md-7 {
    margin-top: 2%;
  }
  @media(max-width: 767px){
    padding: 20px 0;
  }
  p {
    font-size: 1.6em;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }

  }
}
.hero-contact {
  background-color: #edeff1;
  padding: 0;
  .col-md-5{
    background-color: $blk;
    padding: 100px 50px;
    color: $wht;
    @media(max-width: 767px){
      padding:40px 20px;
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 36px;
    }
    a {
      text-decoration: none;
      color: #ccc;
      font-weight: 100;
    }
  }
  .col-md-4 {
    margin-top: 65px;
  }
  .col-md-3 {
    padding: 100px 50px 50px 50px;
    @media(max-width: 1385px){
      padding:60px 50px 50px 50px;
    }
    @media(max-width: 767px){
      padding:40px 20px;
    }

    p {
      font-size:1.2em;
      line-height:1.5em;
      color:lighten($blk,15%);
    }
  }
}
// start modal
.modal-dialog{
  width: 250px;
  text-align: center;
  margin:6em auto;
 }
 .modal-header, .modal-footer{
  background: $primary;
  color: $wht;
 }
 input#username{
  margin-bottom: 20px;
 }
 .modal-title {
   color:$wht;
 }
 .modal-dialog .btn-default {
   width:100%;
   background: $primary;
   border: none;
   color:#fff
 }


 //end modal
footer {
  text-align: center;
  padding-top: 25px;
  background: $secondary;
  color: $blk;
  a {
    text-decoration: none;
    color: $blk;
    &:hover {
      text-decoration: none;
      color:darken($secondary, 35%);
    }
  }
}
